/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface GlobeProps extends React.SVGAttributes<SVGElement> {
size?: string;
}
const Globe: React.SFC<GlobeProps> = ({size, ...props}) => (
  <svg viewBox="0 0 16 19" fill="currentColor" width={ size || "16" } height={ size || "19" } {...props}>
    <path d="M9.005 13.955a6.958 6.958 0 0 1-4.948-2.046 6.937 6.937 0 0 1-2.05-4.94c.002-1.696.603-3.3 1.704-4.568l1.44 1.45a4.96 4.96 0 0 0-1.256 3.282 5 5 0 0 0 5 5c1.325 0 2.52-.524 3.416-1.366l1.296 1.464a6.953 6.953 0 0 1-4.601 1.723m-.11-10.857a4.04 4.04 0 0 1 4.036 4.036 4.04 4.04 0 0 1-4.035 4.037A4.04 4.04 0 0 1 4.86 7.135a4.04 4.04 0 0 1 4.035-4.036m5.77 9.523l.335-.334-2.02-2.282a4.97 4.97 0 0 0 .915-2.87 5 5 0 0 0-5-5 4.95 4.95 0 0 0-3.012 1.033L3.703 1l-.358.32A7.93 7.93 0 0 0 1 6.97c0 2.135.833 4.142 2.345 5.65a7.942 7.942 0 0 0 5.196 2.32L8.543 17h-2v1h5v-1h-2v-2.064a7.946 7.946 0 0 0 5.123-2.315"
      fillRule="evenodd" />
  </svg>
);
Globe.displayName = 'Globe';
export default Globe;
/* tslint:enable */
/* eslint-enable */
