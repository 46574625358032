import {EventType, RegistrationStatus} from '@wix/wix-events-commons-statics'
import * as _ from 'lodash'
import {isMobile} from '../../commons/selectors/environment'
import {
  getListButtonText,
  getOneButtonText,
  getRSVPClosedText,
  getWidgetType,
  isOfferRibbonEnabled,
  isStatusRibbonEnabled,
} from '../../commons/selectors/settings'
import {State} from '../types/state'
import {isForceShowRibbons} from './layout'
import {getComponentConfig, isOfferRibbonVisible, isRibbonVisible, isStatusRibbonVisible} from './settings'

export const getEventById = (state: State, eventId: string) => getEvents(state).find(event => event.id === eventId)

export const getEvents = (state: State): ExtendedEvent[] => {
  if (state.siteSettings.firstEventCreated || state.events.length) {
    return state.events
  }

  return state.demoEvents[getWidgetType(getComponentConfig(state))] as ExtendedEvent[]
}

export const getFirstEvent = (state: State) => getEvents(state)[0]

export const getEventsIds = (state: State) => getEvents(state).map(event => event.id)

const RIBBON_TEXTS = {
  [EventType.TICKETS]: {
    [RegistrationStatus.CLOSED]: 'ribbon.soldOut',
    [RegistrationStatus.CLOSED_MANUALLY]: 'ribbon.soldOut',
  },
  [EventType.RSVP]: {
    [RegistrationStatus.CLOSED]: 'ribbon.rsvpClosed',
    [RegistrationStatus.CLOSED_MANUALLY]: 'ribbon.rsvpClosed',
    [RegistrationStatus.OPEN_RSVP_WAITLIST]: 'ribbon.joinWaitList',
  },
  [EventType.EXTERNAL]: {
    [RegistrationStatus.CLOSED]: 'ribbon.rsvpClosed',
  },
}

const RIBBON_TEXT_FALLBACKS = {
  [EventType.TICKETS]: 'ribbon.soldOut',
  [EventType.RSVP]: 'ribbon.rsvpClosed',
}

export const getRibbonData = (state: State, event: ExtendedEvent) => {
  if (!isRibbonVisible(state, event)) {
    return {visible: false}
  }

  const componentConfig = getComponentConfig(state)
  const mobile = isMobile(state)
  const statusRibbonEnabled = isStatusRibbonEnabled(componentConfig, mobile)
  const offerRibbonEnabled = isOfferRibbonEnabled(componentConfig, mobile)

  if (isForceShowRibbons(state)) {
    if (statusRibbonEnabled) {
      return {
        visible: true,
        text: _.get(RIBBON_TEXT_FALLBACKS, event.registration.type),
        status: true,
        statusVisible: true,
      }
    } else if (offerRibbonEnabled) {
      return {
        visible: true,
        text: 'ribbon.memberOffer',
        status: false,
      }
    }
  }

  const statusText = statusRibbonEnabled
    ? _.get(RIBBON_TEXTS, `${event.registration.type}.${event.registration.status}`)
    : ''
  const offerText = isOfferRibbonVisible(state, event) ? 'ribbon.memberOffer' : ''

  return {
    text: statusText || offerText,
    visible: Boolean(statusText || offerText),
    statusVisible: Boolean(statusText),
    status: Boolean(statusText),
  }
}

export const getRSVPButtonTextForSingleEvent = (state: State, event: ExtendedEvent) => {
  const componentConfig = getComponentConfig(state)
  return isStatusRibbonVisible(state, event) ? getRSVPClosedText(componentConfig) : getOneButtonText(componentConfig)
}

export const getRSVPButtonTextForEventListItem = (state: State, event: ExtendedEvent) => {
  const componentConfig = getComponentConfig(state)
  return isStatusRibbonVisible(state, event) ? getRSVPClosedText(componentConfig) : getListButtonText(componentConfig)
}
